import React from 'react';
import WebTab from './_code-web';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Utility" subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Helpers"
        tierThree="Utility"
      />

      <PlatformTabs
        pageType="resources"
        platforms={{ web: <WebTab data={data} /> }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
