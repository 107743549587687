import React from 'react';
import {
  List,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Visual', 'Type']} />

      <Section title="Visual">
        <Paragraph>
          Use these to quickly override the styling of your components or
          elements.
        </Paragraph>
        <PropList>
          <PropListItem name=".uni-utility__hidden" types={[]}>
            <Paragraph>
              Use this to visually hide an element, but keep it in the DOM for
              accessibility purposes.
            </Paragraph>
          </PropListItem>

          <PropListItem name=".uni-utility__collapse-margin--*" types={[]}>
            <Paragraph>
              Collapse the margins of the element in one of the directions
              below:
            </Paragraph>
            <List type="unordered">
              <li>top</li>
              <li>right</li>
              <li>bottom</li>
              <li>left</li>
              <li>horizontal</li>
              <li>vertical</li>
              <li>all</li>
            </List>
          </PropListItem>

          <PropListItem name=".uni-utility__collapse-padding--*" types={[]}>
            <Paragraph>
              Collapse the paddings of the element in one of the directions
              below:
            </Paragraph>
            <List type="unordered">
              <li>top</li>
              <li>right</li>
              <li>bottom</li>
              <li>left</li>
              <li>horizontal</li>
              <li>vertical</li>
              <li>all</li>
            </List>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Type">
        <Paragraph>
          Quickly align or style text with the classes below.
        </Paragraph>
        <PropList>
          <PropListItem name=".uni-utility__text-align--*" types={[]}>
            <Paragraph>Align text in one of the directions below:</Paragraph>
            <List type="unordered">
              <li>left</li>
              <li>right</li>
              <li>center</li>
            </List>
          </PropListItem>

          <PropListItem name=".uni-utility__font--bold " types={[]}>
            <Paragraph>Bold the enclosed text.</Paragraph>
          </PropListItem>

          <PropListItem name=".uni-utility__text-overflow--hidden" types={[]}>
            <Paragraph>
              Hide overflowing text and clip it with an ellipsis.
            </Paragraph>
          </PropListItem>
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
